<template>
	<div>
		<router-view v-if="!isSpecialRoute" />
		<!-- <AppModal size="md" id="modalLogin" ref="modalLogin"> -->
		<b-modal
			id="unauthorizedModal"
			centered
			size="sm"
			no-close-on-esc
			no-close-on-backdrop
			ref="unauthorizedModal"
			hide-header
			hide-footer
		>
			<div class="text-center h-full flex flex-col">
				<h2 class="text-2xl bold">¡Se cerró tu sesión!</h2>
				<template v-if="unauthorizedError.message">
					<p class="my-4">{{ unauthorizedError.message }}</p>
				</template>

				<div class="mt-auto mb-auto">
					<app-button
						clase="primary"
						style="width: auto"
						v-on:click="goToLogin"
						title="Ir al inicio de sesión"
					>
					</app-button>
				</div>
			</div>
		</b-modal>
		<b-modal
			id="modalLogin"
			centered
			hide-footer
			hide-header
			ref="modalLogin"
			size="sm"
			no-close-on-backdrop
			no-close-on-esc
			:static="true"
			body-class="py-5"
		>
			<app-login
				v-on:close="$refs.modalLogin.hide()"
				:message="unauthorizedError.message"
				ref="loginComponent"
			/>
			<div class="text-center mt-2">
				<span
					class="text-center text-sm tracking-tighter p-0 fw-light"
					style="cursor: pointer"
					v-on:click="goToLogin"
				>
					Inicia sesión en una cuenta diferente
				</span>
			</div>
		</b-modal>
		<!-- </AppModal> -->
		<b-modal
			id="modalConfirmPin"
			no-close-on-backdrop
			no-close-on-esc=""
			centered
			hide-footer
			hide-header
			ref="modalConfirmPin"
			size="lg"
			modal-class="modal-bg-transparent"
			:static="true"
		>
			<app-validate-pin v-on:close="closeModalConfirmPin" ref="validatePinC" />
		</b-modal>

		<app-whatsapp-fab v-if="!whatsappVerify" :phone-number="contactCellphone" />

		<check-data-treatment></check-data-treatment>

		<!-- <aval-modal></aval-modal> -->

		<!-- <webinar-modal></webinar-modal> -->
		<app-pop-up-modal v-if="user"></app-pop-up-modal>

		<BrandUpdate></BrandUpdate>
	</div>
</template>

<script>
import * as singleSpa from 'single-spa';
import { specialRoutes, whatsappExclude } from '@superpagos/mf-services';
import { mapGetters } from 'vuex';
import { mutations, actions } from '@superpagos/mf-store';
import { BASE_URL } from '@/common/config';
import AppLogin from './components/auth/AppLogin.vue';
import AppValidatePin from '@/components/auth/AppValidatePin.vue';
import { AppWhatsappFab } from '@superpagos/mf-custom-components';
import { initSocket, disconnectSocket } from './lib/socket';
import checkDataTreatment from './features/checkDataTreatment';
// import AvalModal from './features/AvalModal';

// Webinar Modal - transfiya
// import WebinarModal from './features/WebinarModal/WebinarModal.vue';
import { AuthService } from '@superpagos/mf-services';
import { initBrand, BrandUpdate } from './features/brandConfig';
import AppPopUpModal from './features/AppPopUpModal';
import { disableRightClick, disableInspectPage } from './utils/eventBlockers';

export default {
	name: 'App',
	components: {
		AppLogin,
		AppValidatePin,
		AppWhatsappFab,
		checkDataTreatment,
		BrandUpdate,
		AppPopUpModal
		// AvalModal,
		// WebinarModal
	},
	data() {
		return {
			unauthorizedError: {
				data: {}
			},
			idThread: null,
			location: window.location.pathname,
			isSpecialRoute: false,
			whatsappVerify: false
		};
	},
	mounted() {
		initBrand();
		this.decideAction(this.$route.path);
		initSocket({ user: this.user, store: this.$store });

		if (BASE_URL == window.location.host) {
			this.loading = false;
		} else {
			// this.loadBrand();
		}
	},
	beforeMount() {
		const channel = window.localStorage.getItem('channel');
		const userStored = AuthService.getUser();
		if (!userStored && channel !== 'app') this.validateTokenActive();

		disableRightClick();
		disableInspectPage();
	},
	methods: {
		validateTokenActive() {
			const token = this.user?.token;
			this.$store.dispatch(actions.VALIDATE_TOKEN_ACTIVE, token);
		},
		decideAction(path) {
			this.isSpecialRoute = specialRoutes.some(e => path.includes(e));
			this.whatsappVerify = whatsappExclude.some(e => path.includes(e));
		},
		navigateTo(path) {
			singleSpa.navigateToUrl(path);
		},
		handleUnauthorized(error) {
			if (!this.isSpecialRoute) {
				window.clearInterval(this.idThread);
				this.unauthorizedError = error;
				if (error.login && !this.$refs.loginComponent.showing) {
					this.$refs.loginComponent.next();
					return this.$refs.modalLogin.show();
				}
				if (error.notify) {
					this.$refs.unauthorizedModal.show();
				}
				if (error.confirmDevice) {
					this.$refs.modalConfirmPin.show();
					this.$refs.validatePinC.setError(error);
				}
				if (error.limitExceeded) {
					singleSpa.navigateToUrl('/ActiveChargeMaxTip');
				}
			}
		},
		goToLogin() {
			disconnectSocket();
			this.$store.commit(mutations.SET_LOGOUT);
			setTimeout(() => {
				this.navigateTo('/login');
				location.reload(true);
			}, 500);
			// this.$router.replace({ name: 'login' });
			// this.$refs.unauthorizedModal.hide();
		},
		closeModalConfirmPin() {
			this.$refs.modalConfirmPin.hide();
		}
	},
	computed: {
		...mapGetters(['brand', 'user', 'unauthorized', 'globalVersion']),
		contactCellphone() {
			return '57' + this.user?.contactCellphone ?? '3172309003';
		}
	},
	watch: {
		path() {
			// console.log(value);
		},
		'$route.path'(value) {
			this.decideAction(value);
		},
		unauthorized(u) {
			this.handleUnauthorized(u);
		},
		'user.token': function () {
			initSocket({ user: this.user, store: this.$store });
		}
	}
};
</script>

<style lang="scss" scoped></style>
